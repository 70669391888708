.container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.heading {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 1.8rem;
	color:white;
}

.subheading {
    text-align: center;
    color: #555;
    margin-bottom: 20px;
    font-size: 1.2rem;
}

.error {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.success {
    color: green;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.form {
    max-width: 600px;
    margin: 0 auto;
}

.formGroup {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #555;
}

input, select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

.submitButton {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background-color: #0056b3;
}

.existingQuestionsHeading {
    margin-top: 40px;
    font-size: 1.5rem;
    color: white;
	text-align: center;
}

.questionList {
    list-style: none;
    padding: 0;
	max-width: 1200px;
	margin:0 auto;
}

.questionItem {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
	color:white;
}

.deleteButton {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.deleteButton:hover {
    background-color: #c82333;
}
