@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.gamePage {
    text-align: center;
}

.videoContainer {
    position: relative;
    width: 100%;
    max-width: 800px;
    aspect-ratio: 16 / 9;
    margin: 0 auto 5px;
    overflow: hidden;
}

.videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.videoOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: not-allowed;
}
.questionWrapper{
	position:relative;
}
.instructions{
	color: white;
    font-size: 1.2rem;
    background: #295372;
    position: absolute;
    z-index: -1;
    margin: 0 auto;
	left:0;
	right:0;
    padding: 20px;
    border-radius: 10px;
	max-width:800px;
}
h1.header{
	color:white;
}
h2.subheader {
    color: white;
}

.questionContainer {
    padding: 20px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    max-width: 800px;
    margin: 20px auto;
}
.questionContainer p{
	color:#0d933e;
	font-weight:bold;
}
.questionContainer h3 {
    margin-bottom: 20px;
    font-size: 18px;
}

.choices {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
    flex-wrap: wrap;
}

.startButton{
	min-width: 100px;
    max-width: 200px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
	margin:20px 0;
}
.choices button {
    flex: 1;
    min-width: 100px;
    max-width: 200px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;
}

.choices button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.choices button:hover {
    background-color: #0056b3;
}

.summaryContainer {
    margin-top: 20px;
    padding: 20px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    max-width: 800px;
    margin: 0 auto;
	min-height:200px;
}
.summaryContainer h2{
	Color:#007bff;
}
.summaryContainer ul div:first-of-type p {;
  font-size: 30px;
  margin: 10px 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.summaryContainer p{
	Color:#007bff;
	font-weight: bold;
	font-size: 20px;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
}
.summaryContainer button{
	flex: 1;
    min-width: 100px;
    max-width: 200px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;
	margin:10px;
}

.sideMenu {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    transition: width 0.3s ease;
    overflow: hidden;
    z-index: 9;
}

.sideMenu.open {
    width: 140px;
    background-color: #333;
}

.menuTab {
    background-color: #444;
    color: white;
    text-align: center;
    cursor: pointer;
    padding: 10px;
}

.menuContent {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.menuContent button {
    background-color: #555;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    text-align: left;
}

.menuContent button:hover {
    background-color: #777;
}

.videoContainer.hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    height: 0;
}

.confettiWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000;
}


.videoWrapper{

}
.videoOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent; /* Make sure it's transparent */
    z-index: 1; /* Place it above the video player */
}
.toggleButton {
    background-color: #007BFF; /* Blue background */
    color: #FFFFFF; /* White text */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 0;
    transition: background-color 0.3s, transform 0.2s;
}

.toggleButton:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.toggleButton:active {
    transform: scale(0.98); /* Slightly shrink on click */
}

.toggleButton:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Outline for accessibility */
}
.buttonContainer {
    display: flex;
    justify-content: center;
}

.iconButton {
    background: none;
    border: none;
    margin: 0 10px;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
	color:white;
    transition: background-color 0.3s;
}

.iconButton:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.iconButton:focus {
    outline: none;
}
.scoreContainer{
	display:flex;
	max-width: 800px;
    justify-content: space-between;
    margin: 0 auto;
}
.scoreContainer h2{
	margin:0;
	color:white;
	
}
.fadeOut {
    animation: fadeOut 4s ease-out forwards;
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@media (max-width: 768px) {
    .choices {
        flex-direction: column;    /* Stacks buttons vertically */
        align-items: center;       /* Centers items horizontally */
    }

    .choices button {
        width: 80%;                /* Makes buttons wider on mobile */
    }

    .questionContainer {
        padding: 15px;
        font-size: 16px;
    }

    .questionContainer h3 {
        font-size: 16px;
    }

    .menuContent button {
        font-size: 14px;
        padding: 8px;
    }

    .sideMenu.open {
        width: 100px;
    }
	.instructions{

    margin: 20px;
	left:0;
	right:0;

}
}
@media (max-width: 480px) {
		.questionContainer {
    	margin: 20px;
		padding-bottom:40px;
}
}
