.hostGame {
    text-align: center;
    padding: 20px;
    color: #fff;
}

.hostGame h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.hostGame h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #007bff;
}

.error {
    color: #ff4d4d;
    font-size: 1.2rem;
    margin-bottom: 20px;
}
.startButton{
	background-color: #007BFF; /* Blue background */
    color: #FFFFFF; /* White text */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    margin: 30px 0;
    transition: background-color 0.3s, transform 0.2s;
}

.videoList {
    display: grid;
    grid-template-columns: repeat(auto-fit, 140px); /* Fixed item width */
    gap: 15px;
    justify-content: center; /* Centers the grid items */
    list-style: none;
    padding: 0;
    margin: 0 auto;
    max-width: calc(140px * 5 + 15px * 4);
}

.videoItem {
    background: #1c1c1c;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
	margin:10px 0;
}

.videoItem:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.videoThumbnailContainer {
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
    background: #2c2c2c;
}

.videoThumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.videoDetails h3 {
    font-size: 1rem;
    margin: 5px 0;
    color: #fff;
}

.videoDetails p {
    font-size: 0.8rem;
    color: #ccc;
    margin: 0;
}

button.creatGame {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

button.creatGame:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

button.creatGame:hover:not(:disabled) {
    background-color: #0056b3;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

li {
    display: flex;
    align-items: center;
    gap: 10px;
}

img {
    border-radius: 5px;
    object-fit: cover;
}

strong {
    color: #28a745;
}

p {
    font-size: 1.2rem;
    color: #ccc;
}
.filterDropdown {
    margin: 15px 0;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
	max-width: 300px;
}
.pagination {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.pagination button {
    padding: 10px 15px;
    font-size: 0.7rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff !important;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.pagination span {
    font-size: 1.2rem;
    color: #fff;
}
.sessionDetails {
    margin-top: 20px;
    padding: 20px;
    background: #1c1c1c;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #fff;
    text-align: center;
}

.sessionDetails h2 {
    font-size: 1.5rem;
    color: #28a745;
    margin-bottom: 10px;
}

.playerList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    list-style: none;
    padding: 0;
    margin: 20px auto;
    max-width: 1200px;
}

.playerItem {
    background: #2c2c2c;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.playerItem img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.sessionCode {
    font-size: 1.2rem;
    color: #28a745;
    margin: 15px 0;
}
.subscribeContainer {
    margin: 20px auto;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
	border-top:1px solid grey;
}

.subscribeContainer h2 {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #ccc;
}
@media (max-width: 768px) {
	.pagination button {
    padding: 10px 5px;
		width:100px;

}
	.pagination {
    align-items: flex-end;
}
	.hostGame h1 {
    font-size: 2rem;
}
}


