.joinGame {
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    color: white;
}

.heading {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #f8f9fa;
}

.inputContainer {
    margin-bottom: 20px;
}

.inputContainer label {
    display: block;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #ced4da;
	text-align: center;
}

.inputContainer i {
    margin-right: 5px;
    color: #007bff;
}
.information{
	max-width:800px;
	margin:0 auto;
	color:white;
	padding:0 20px;
}

.input {
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    border: 1px solid #ced4da;
    border-radius: 5px;
    background-color: #1e1e1e;
    color: white;
    outline: none;
}

.input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.joinButton {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: background-color 0.3s ease;
	margin:0 auto;
}

.joinButton:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.joinButton:hover:not(:disabled) {
    background-color: #0056b3;
}

.error {
    color: red;
    margin-top: 10px;
    font-size: 1rem;
}
@media (max-width: 480px) {
.information{

	margin:0 20px;;

}
}
