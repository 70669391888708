.manageVideosContainer {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.heading {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.8rem;
    text-align: center;
	color:white;
}
.responsiveTable{
	overflow-x: auto;
}
tr {
   background: #a5a5a5;
}

.error {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.actionButtons {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 15px;
	max-width: 500px;
    margin: 0 auto;
}

.actionButton {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
	font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    gap: 5px;
	width: 200px;
    margin: 0 auto;
	justify-content: center;
}

.actionButton i {
    font-size: 1rem;
}

.actionButton:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.deleteButton {
    background-color: #dc3545;
}

.deleteButton:hover {
    background-color: #c82333;
}

.videoTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.videoTable th,
.videoTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-size: 1rem;
	font-weight:bold;
}

.videoTable th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.thumbnail {
    width: 150px;
    height: auto;
    border-radius: 5px;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
