body {
	background:#171717;
}
/* General Header Styles */
.header {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Arial, sans-serif;
}

/* Header Content */
.headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap; /* Enable wrapping for small screens */
}

/* Logo */
.logo {
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    margin-right: auto; /* Push logo to the left */
    color: white;
    text-decoration: none;
}

/* User Info */
.headerUserInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align text to the right */
    gap: 5px;
    flex: 1; /* Allow flexible space allocation */
}

/* User Buttons Container */
.headerUserButtons {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 10px; /* Space between buttons */
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

/* Buttons */
.header button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-align: center;
}

button.setGameButton {
    background-color: #0056b3 ;
}

button.setGameButton:hover {
    background-color: #0056b3;
}

button.dashboardButton {
    background-color: #0056b3;
}

button.dashboardButton:hover {
    background-color: #0056b3;
}

button.logoutButton {
    background-color: #dc3545;
}

button.logoutButton:hover {
    background-color: #c82333;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .headerContent {
        flex-direction: row; /* Keep items in a row */
        align-items: center;
    }

    .headerUserButtons {
        justify-content: center; /* Center buttons on medium screens */
        width: 100%; /* Take full width */
    }
	.logo {
    margin: 0 auto; /* Push logo to the left */
}
}

@media (max-width: 480px) {
    .headerContent {
        flex-direction: column; /* Stack items vertically */
        align-items: center;
    }

    .headerUserInfo {
        align-items: center; /* Center-align text */
    }

    .headerUserButtons {
        justify-content: center; /* Center buttons */
		flex-wrap: nowrap;
    }

}
