.setGamePage {
    text-align: center;
    padding: 50px;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.setGamePage input{
	margin-bottom:20px;
}

.heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: white;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 400px;
}

.setGameButton {
    background-color: #007bff;
    color: white;
    padding: 15px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: background-color 0.3s ease;
	width: 100%;

}

.setGameButton i {
    font-size: 1.5rem;
}

.setGameButton:hover:not(:disabled) {
    background-color: #0056b3;
}

.setGameButton:focus {
    outline: 2px solid #0056b3;
}

.setGameButton:disabled {
    display: none;
}
.disabledButton {
    background-color: #d3d3d3; /* Light gray for disabled state */
    color: #7a7a7a; /* Dark gray text */
    cursor: not-allowed; /* Change cursor to indicate disabled */
    pointer-events: none; /* Prevent interaction */
    opacity: 0.6; /* Make it look inactive */
}

@media (max-width: 768px) {
		.buttonContainer {
			align-items: center;
		}
	.heading {
    font-size: 2rem;
	margin-top:0;
    margin-bottom: 20px;
}
}
