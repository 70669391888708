/* General Container Styling */
.container {
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    border-radius: 10px;
    max-width: 800px;
    margin: 20px auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Headings */
h1.profile {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #343a40;
}

h2.profile {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #007bff;
}

h3.profile {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #495057;
	text-align: left;
}

/* Form Group */
.formGroup {
    margin-bottom: 20px;
    text-align: left;
}

/* Labels */
label {
    display: block;
    margin-bottom: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    color: #495057;
	text-align: left;
}

/* Input Fields */
input[type="text"],
input[type="file"],
textarea {
    padding: 10px;
    width: 100%;
    max-width: 400px;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #fff;
    color: #495057;
}

input[type="text"]:focus,
textarea:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

/* Buttons */
.submitButton {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background-color: #0056b3;
}

/* Error and Success Messages */
.error {
    color: #d9534f;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: 0.9rem;
}

.success {
    color: #28a745;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: 0.9rem;
}

/* Profile Picture Preview */
.profilePicturePreview img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

/* Rewards Section */
.rewardsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
	background: #ced4da;
    min-height: 100px;

}

.rewardItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9rem;
    color: #495057;
    text-align: center;
}

.rewardIcon {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ced4da;
}

/* Responsive Design */
@media (max-width: 768px) {
    .container {
        padding: 15px;
        max-width: 95%;
    }

    h1 {
        font-size: 1.8rem;
    }

    h2 {
        font-size: 1.3rem;
    }

    h3 {
        font-size: 1rem;
    }

    input[type="text"],
    textarea {
        max-width: 100%;
    }
}
