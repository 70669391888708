.adminContainer {
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
}

.heading {
    font-size: 2rem;
    color: #343a40;
    margin-bottom: 20px;
	color:white;
}

.description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
}

.adminActions {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 400px;
	margin:0 auto;
}

.actionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-decoration: none;
}

.actionButton i {
    font-size: 1.2rem;
}

.actionButton:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}
@media (max-width: 768px) {
	.adminActions {
			align-items: center;
	}
}
