.manageCategoriesContainer {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.heading {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.8rem;
    text-align: center;
	color:white;
}

.subheading {
    margin-top: 30px;
    color: #555;
    font-size: 1.4rem;
	text-align: center;
}

.error {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.success {
    color: green;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin-bottom: 10px;
}

.label {
    margin-bottom: 5px;
    font-size: 1rem;
    color: #555;
}

.input {
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.addButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.addButton:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.categoryList {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    max-width: 500px;
}

.categoryItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.categoryName {
    font-size: 1rem;
    color: #333;
}

.deleteButton {
    padding: 5px 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 5px;
}

.deleteButton i {
    font-size: 0.9rem;
}

.deleteButton:hover {
    background-color: #c82333;
}
