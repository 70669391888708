.manageUsersContainer {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.heading {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.8rem;
    text-align: center;
	color:white;
}
.responsiveTable{
	overflow-x: auto;
}

.error {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.userTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.userTable th,
.userTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-size: 1rem;
	font-weight:bold;
	background: #a5a5a5;
}

.userTable th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.actionButton {
    margin-right: 5px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
	margin-top:15px;
}

.actionButton i {
    font-size: 1rem;
}

.actionButton:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.disabledButton {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    border: 1px solid #aaa;
}

.actionButton:disabled {
    opacity: 0.6;
}
