.registrationContainer {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

.registrationForm label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.registrationForm input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.registrationForm button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.registrationForm button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.error {
    color: red;
    margin-bottom: 15px;
}

.backToLogin {
    margin-top: 15px;
    text-align: center;
}

.backToLogin a {
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}

.backToLogin a:hover {
    color: #0056b3;
}
@media (max-width: 768px) {
    .registrationContainer {
       margin: 20px auto;
        padding: 15px;
    }

    .registrationContainer h1 {
        font-size: 20px;
    }

    .registrationContainer button {
        font-size: 14px;
        padding: 8px;
    }
}
