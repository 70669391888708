.addVideoContainer {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.heading {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 1.8rem;
	color:white;
}

.error {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.success {
    color: green;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.form {
    max-width: 500px;
    margin: 0 auto;
}

.formGroup {
    margin-bottom: 15px;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #555;
}

.input, .select, .textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

.textarea {
    resize: vertical;
}

.submitButton {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.submitButton:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}
