.passwordResetContainer {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

.passwordResetContainer h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.passwordResetForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.passwordResetForm label {
    font-size: 14px;
    color: #555;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.passwordResetForm input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
}

.passwordResetForm input:focus {
    border-color: #007bff;
}

.passwordResetForm button {
    padding: 10px;
    font-size: 16px;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.passwordResetForm button:hover {
    background-color: #0056b3;
}

.passwordResetForm button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.success {
    color: #28a745;
    font-size: 14px;
    text-align: center;
    margin-bottom: 15px;
}

.error {
    color: #dc3545;
    font-size: 14px;
    text-align: center;
    margin-bottom: 15px;
}

.backToLogin {
    margin-top: 15px;
    text-align: center;
}

.backToLogin a {
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}

.backToLogin a:hover {
    color: #0056b3;
}

@media (max-width: 768px) {
    .passwordResetContainer {
        margin: 20px auto;
        padding: 15px;
    }

    .passwordResetContainer h1 {
        font-size: 20px;
    }

    .passwordResetForm button {
        font-size: 14px;
        padding: 8px;
    }
}
