.videoSelection {
    text-align: center;
    padding: 20px;
    color: #fff;
}

.videoSelection h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #007bff;
}

.error {
    color: #ff4d4d;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.videoList {
    display: grid;
    grid-template-columns: repeat(auto-fit, 140px); /* Fixed item width */
    gap: 15px;
    justify-content: center; /* Centers the grid items */
    list-style: none;
    padding: 0;
    margin: 0 auto;
    max-width: calc(140px * 5 + 15px * 4);
}

.videoItem {
    background: #1c1c1c;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
	margin:10px 0;
}

.videoItem:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.videoThumbnailContainer {
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
    background: #2c2c2c;
}

.videoThumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.videoDetails h3 {
    font-size: 1rem;
    margin: 5px 0;
    color: #fff;
}

.videoDetails p {
    font-size: 0.8rem;
    color: #ccc;
    margin: 0;
}
.filterDropdown {
    margin: 15px 0;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
	max-width: 300px;
}
.pagination {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.pagination button {
    padding: 10px 15px;
    font-size: 0.7rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff !important;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.pagination span {
    font-size: 1.2rem;
    color: #fff;
}
.subscribeContainer {
    margin: 20px auto;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
	border-top:1px solid grey;
}

.subscribeContainer h2 {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #ccc;
}

@media (max-width: 768px) {
	.pagination button {
    padding: 10px 5px;
		width:100px;

}
	.pagination {
    align-items: flex-end;
}
	.videoSelection h1 {
    font-size: 2rem;
}
}
