/* Container Styling */
.loginContainer {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}
.logo{
	max-width:100%;
}

/* Heading Styling */
.heading {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

/* Form Label Styling */
.label {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
}

/* Input Styling */
.input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
}

/* Input Focus Styling */
.input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
}

/* Error Message Styling */
.error {
    color: #d9534f;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: 14px;
    text-align: left;
}

/* Button Styling */
.button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Button Hover Styling */
.button:hover {
    background-color: #0056b3;
}

/* Links below the form */
.loginLinks {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.link {
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}

.link:hover {
    color: #0056b3;
}

.guestButton {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    background-color: #6c757d;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.guestButton:hover {
    background-color: #5a6268;
}
@media (max-width: 768px) {
    .loginContainer {
        margin: 20px auto;
        padding: 15px;
    }

    .loginContainer h1 {
        font-size: 20px;
    }

    .loginContainer button {
        font-size: 14px;
        padding: 8px;
    }
}
@media (max-width: 480px) {
    .loginContainer {
        margin: 20px;

    }

}
